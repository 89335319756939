<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
          <div class="row w-100">
            <div class="col-lg-4 mx-auto">
              <!-- Header form -->
              <div class="header-form mb-2 py-2 text-center">
                <img src="/static/img/logos/REDEMA.png">
              </div>
              <!-- . Header form -->
              <!-- Form -->
              <div class="auto-form-wrapper">
                <b-form v-on:submit.prevent="startLogin">
                  <div class="form-group">
                    <label class="label">Email</label>
                    <b-form-input required v-model="email" placeholder="Email"></b-form-input>
                  </div>

                  <div class="form-group">
                    <label class="label">Contraseña</label>
                    <b-form-input
                      v-model="password"
                      type="password"
                      placeholder="Contraseña"
                      required
                    ></b-form-input>
                  </div>

                  <div class="form-group">
                    <b-button type="submit" class="btn btn-primary submit-btn btn-block" variant="primary">Entrar</b-button>
                  </div>

                  <div class="form-group">
                    <small class="text-danger">
                      {{ error }}
                    </small>
                  </div>
                  
                </b-form>
              </div>
              <!-- . Form -->

              <!-- Pre footer -->
              <div class="pre-footer px-3 py-2 mt-2 text-center">
                  <a href="http://www.famp.es/es/redes-observatorios/redema/" target="_blank">
                      <img src="/static/img/logos/FAMP.png" class="mr-3">
                  </a>
                  <img src="/static/img/logos/EU_Development_Fund.png">
                  <img src="/static/img/logos/SUPPORT.jpg">
              </div>
              <!-- . Pre footer -->
              <!-- Footer form -->
              <ul class="auth-footer">
                <li>
                  <router-link :to="{ name: 'conditions' }">Condiciones de uso</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'about' }" href="#">Acerca de SUPPORT</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'functionalities' }" href="#">Funcionalidades del Observatorio</router-link>
                </li>
              </ul>
              <p class="footer-text text-center">Copyright © 2021 REDEMA. Todos los derechos reservados.</p>
              <!-- . Footer form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",

  data: () => ({
    email: undefined,
    password: undefined,

    error: null,
  }),

  methods: {
    ...mapActions("auth", ["login"]),

    async startLogin() {
      try {
        await this.login({ email: this.email, password: this.password });

        this.$router.push({ name: "listBuildings" });
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            this.error =
              "Los credenciales de acceso no corresponden a ningun usuario.";
          } else {
            this.error = "Ha habido un error.";
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-form img {
  max-width: 250px;
}
.header-form, .pre-footer {
  background: #FFF;
  border-radius: 4px;
}
.pre-footer img {
  max-height: 55px;
  max-width: 175px;
}
</style>
